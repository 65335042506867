<template>
  <video-player
    class="vjs-facebook vjs-big-play-centered"
    :class="surveyStep === 'stimulus' ? 'disable-controls' : ''"
    ref="videoPlayer"
    :playsinline="true"
    :options="playerOptions"
    @playing="handlePlaying"
    @pause="handlePause"
    @ended="handleEnded"
    @timeupdate="handleTimeUpdate"
    @loadeddata="setStimulusDuration"
  >
  </video-player>
</template>

<script>
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import { mapGetters } from "vuex";

export default {
  emits: ["startRecording", "stopRecording"],
  props: ["src", "isStimulus"],
  components: {
    videoPlayer,
  },
  data() {
    return {
      topMargin: window.innerWidth > 767 ? "-56px" : "-115px",
      viewportWidth: window.innerWidth,
      previousPosition: 0,
      observer: null,
      replay: 1,
      initialTimestamp: Date.now(),
      findNextSecondStartInterval: null,
    };
  },
  mounted() {
    this.createObserver();
    window.addEventListener("resize", this.onViewportResize);
  },
  beforeDestroy() {
    this.destroyObserver();
    this.surveyStepFinished();
    window.removeEventListener("resize", this.onViewportResize);
  },
  watch: {
    viewportWidth: {
      // This watcher destroys and recreates the intersection observer based on the new width of the window (mobile or desktop header)
      // It's a nasty workaround to take in account the header height change and update the top margin of the observer
      handler(newVal, oldVal) {
        if (newVal >= 768 && oldVal <= 767) {
          // Desktop header
          this.topMargin = "-56px";
          this.destroyObserver();
          this.createObserver();
        }
        if (newVal <= 767 && oldVal >= 768) {
          // Mobile header
          this.topMargin = "-115px";
          this.destroyObserver();
          this.createObserver();
        }
      },
    },
  },
  computed: {
    ...mapGetters("stimulus", {
      stimulus: "getStimulus",
    }),
    ...mapGetters("facebook", {
      surveyStep: "getSurveyStep",
      playStimulus: "getPlayStimulus",
    }),
    playerOptions() {
      return {
        controls: true,
        fluid: true,
        preload: false,
        // muted: true, needed on ios for video.play() to work (Workaround possible???)
        loop: this.surveyStep === "scroll",
        controlBar: {
          children: {
            playToggle: {},
            progressControl: {},
            currentTimeDisplay: {},
            fullscreenToggle: {},
            volumePanel: { inline: false },
          },
        },
        sources: [
          {
            src: this.src,
            type: "video/mp4",
          },
        ],
        poster: "",
      };
    },
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  methods: {
    startRecording({ position, replay, timestamp }) {
      this.$parent.$parent.$parent.$emit("startRecording", {
        stimulus: this.stimulus[0],
        position,
        replay,
        timestamp,
        phase: this.surveyStep === "scroll" ? 1 : 2,
      });
    },
    stopRecording() {
      this.$parent.$parent.$parent.$emit("stopRecording", {
        stimulus: this.stimulus[0],
      });
    },
    finishedRecording() {
      this.$parent.$parent.$parent.$emit("finishedRecording");
    },
    onViewportResize() {
      this.viewportWidth = window.innerWidth;
    },
    createObserver() {
      this.observer = new IntersectionObserver(
        (entry) => {
          if (entry[0].isIntersecting) {
            if (this.player.paused()) this.player.play();
          } else if (!entry[0].isIntersecting) {
            if (!this.player.paused()) this.player.pause();
          }
        },
        {
          rootMargin: `${this.topMargin} 0px 0px 0px`,
          threshold: [0.8],
        }
      );
      this.observer.observe(this.$refs.videoPlayer.$el);
    },
    destroyObserver() {
      if (this.observer) this.observer.disconnect();
    },
    handlePlaying() {
      if (this.isStimulus) {
        if (this.findNextSecondStartInterval) {
          clearInterval(this.findNextSecondStartInterval);
        }
        this.findNextSecondStartInterval = setInterval(() => {
          if (this.checkFirstDecimal(this.player.currentTime())) {
            this.startRecording({
              position: this.positionRounded(),
              replay: this.replay,
              timestamp: Date.now() - this.initialTimestamp,
              phase: this.surveyStep === "scroll" ? 1 : 2,
            });
            clearInterval(this.findNextSecondStartInterval);
          }
        }, 10);
      }
    },
    checkFirstDecimal(number) {
      const decimalPart = number % 1;
      const decimalString = decimalPart.toString();
      // check millisecond decimal
      if (decimalString[2] === "0" || decimalString[2] === "1") {
        return true;
      } else {
        return false;
      }
    },
    handlePause() {
      if (this.isStimulus) this.stopRecording();
      if (this.findNextSecondStartInterval) {
        clearInterval(this.findNextSecondStartInterval);
      }
    },
    handleEnded() {
      this.$store.commit("facebook/SET_SURVEY_STEP", "finish");
      this.$store.commit("facebook/SET_SHOW_PROMPT", true);
    },
    positionRounded() {
      return Math.round(this.player.currentTime());
    },
    handleTimeUpdate() {
      if (
        this.isStimulus &&
        this.surveyStep === "scroll" &&
        this.previousPosition > this.positionRounded()
      ) {
        this.stopRecording();
        this.replay++;
        this.startRecording({
          position: this.positionRounded(),
          replay: this.replay,
          timestamp: Date.now() - this.initialTimestamp,
          phase: this.surveyStep === "scroll" ? 1 : 2,
        });
        this.previousPosition = this.positionRounded();
      } else {
        this.previousPosition = this.positionRounded();
      }
    },
    surveyStepFinished() {
      this.handlePause();
    },
    setStimulusDuration() {
      if (this.isStimulus) {
        this.$store.commit(
          "facebook/SET_SURVEY_STEP_DURATION",
          Math.floor(this.player.duration())
        );
      }
    },
  },
};
</script>

<style lang="scss">
.disable-controls {
  .video-js.vjs-playing .vjs-tech {
    pointer-events: none;
  }
  .vjs-control-bar {
    .vjs-play-control {
      pointer-events: none;
    }
  }
}

.vjs-facebook {
  .vjs-big-play-button {
    height: 2em;
    width: 2em;
    border-radius: 100%;
    border-width: 3px;
    .vjs-icon-placeholder::before {
      content: "";
      top: 4px;
      left: 7px;
      background: url("../../assets/img/Facebook/Play.svg") no-repeat 0 0;
      background-size: 1.5em;
    }
  }
  .vjs-control-bar {
    background-color: transparent;
    .vjs-playing .vjs-icon-placeholder::before {
      content: "";
      background: url("../../assets/img/Facebook/Pause.svg") no-repeat 0 0;
      background-size: 1em;
      background-position: center;
    }
    .vjs-paused .vjs-icon-placeholder::before {
      content: "";
      background: url("../../assets/img/Facebook/Play.svg") no-repeat 0 0;
      background-size: 1em;
      background-position: center;
    }
    .vjs-progress-control {
      pointer-events: none;
      .vjs-progress-holder {
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 50px;
        height: 8px;
        font-size: 0.9em !important;
      }
      .vjs-slider:focus {
        text-shadow: none;
        box-shadow: none;
      }
      .vjs-play-progress:before {
        top: -0.1rem;
      }
      .vjs-play-progress {
        background-color: #4080ff;
        border-radius: 50px;
      }
      .vjs-load-progress {
        border-radius: 50px;
        div {
          background-color: #aeaeaf;
          border-radius: 50px;
        }
      }
    }
  }
  .vjs-time-control {
    display: block;
  }
  .vjs-duration {
    padding-left: 0.2rem;
  }
  .vjs-mouse-display {
    display: none !important;
  }
  .vjs-time-tooltip {
    display: none !important;
  }

  .vjs-fullscreen-control {
    .vjs-icon-placeholder::before {
      content: "";
      background: url("../../assets/img/Facebook/Maximize.svg") no-repeat 0 0;
      background-size: 0.9em;
      background-position: center;
    }
  }
  .vjs-fullscreen .vjs-fullscreen-control {
    .vjs-icon-placeholder:before {
      background: url("../../assets/img/Facebook/Minimize.svg") no-repeat 0 0;
      background-size: 1em;
      background-position: center;
      content: "";
    }
  }
  .vjs-volume-panel {
    .vjs-volume-control {
      background-color: transparent;
    }
    .vjs-volume-bar {
      width: 8px;
      border-radius: 50px;
    }
    .vjs-volume-level {
      background-color: #4080ff;
      width: 8px;
      border-radius: 50px;
      &::before {
        left: -0.14rem;
      }
    }
  }
  .vjs-play-progress:before {
    font-size: 1.3em;
  }
  .vjs-volume-level:before {
    font-size: 1.3em;
  }
}
</style>
